import { SA } from "@styled-components/STags";

export function InvestmentConfirmContent() {
  return (
    <div>
      <p>
        Die Smavesto GmbH bietet die Möglichkeit, eine nachhaltige
        Vermögensverwaltung nach Art. 8 der Transparenzverordnung (EU) 2019/2088
        abzuschließen. Hierbei handelt es sich um eine Anlagestrategie, die
        wichtigste nachteilige Auswirkungen auf Nachhaltigkeitsfaktoren
        (ESG-Strategieprodukt mit Berücksichtigung von Umwelt- und Sozialthemen)
        berücksichtigt (PAI).
      </p>

      <p>
        Unter anderem wird bei der Durchführung der Vermögensverwaltung
        grundsätzlich in Produkte investiert, die bei der
        Nachhaltigkeitsrating-Agentur MSCI ein Nachhaltigkeitsrating von
        mindestens „AA“ (auf der Skala von AAA bis CCC) erhalten und damit von
        uns als nachhaltige Produkte angesehen werden. Nähere Informationen
        entnehmen Sie bitte unserer Nachhaltigkeitsrichtlinie, die Sie&nbsp;
        <SA
          style={{ color: "#000", fontWeight: "bold" }}
          href="https://smavesto.de/files/32/Nachhaltigkeitsbezogene_Offenlegung_der_Smavesto_GmbH_06_2024_1.pdf"
          target="_blank"
        >
          <u>hier</u>
        </SA>
        &nbsp;finden.
      </p>

      <p style={{ margin: 0 }}>
        Darüber hinaus weisen wir Sie darauf hin, dass es auch andere
        Nachhaltigkeitsstrategien gibt, die bei Smavesto nicht auswählbar sind.
        Zum Beispiel Anlagestrategien, bei denen ein bestimmter Mindestanteil in
        nachhaltige Aktivitäten im Sinne der Offenlegungs-VO investiert wird
        oder deren Anlagestrategie darauf ausgerichtet ist, einen definierten
        Mindestanteil in ökologischen Aktivitäten im Sinne der Taxonomie-VO zu
        investieren.“
      </p>
    </div>
  );
}
