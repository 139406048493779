function formatLinebreaks(text: string) {
  const lines = text.split("\n");
  return lines.map(line => (
    <>
      {line}
      <br />
      <br />
    </>
  ));
}

export default formatLinebreaks;
