import type { FormInstance } from "antd";

import { FormInModal } from "@components/form/inputs/form-fields/FormInModal";
import { RadioGroup } from "@components/form/inputs/form-fields/RadioGroup";
import useOnboardingLabels from "@hooks/onboarding/labels/useOnboardingLabels";
import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

import {
  SActionButtonsWrapper,
  SButton
} from "@styled-components/form/SButtons";
import { SFormItem } from "@styled-components/form/SFormItems";

import { RiskSlider } from "../../FinancialPosition/strategy/RiskSlider";
import { useIsUnderageMode } from "../../hooks";
import { useRiskModalForm } from "./useRiskModalForm";

type RiskModalFormProps = {
  parentForm: FormInstance;
  destroyModal: () => void;
};

export const RiskModalForm: React.FunctionComponent<RiskModalFormProps> = ({
  parentForm,
  destroyModal
}) => {
  const {
    form,
    strategy,
    risk,
    onRiskSliderAfterChange,
    onStrategyChange,
    errorList,
    errorMessages,
    isSubmit,
    onFinish,
    onCancel
  } = useRiskModalForm(parentForm, destroyModal);
  const labels = useOnboardingLabels();

  const isUnderageMode = useIsUnderageMode();

  return (
    <FormInModal<{
      investmentRisk: number;
      strategy: FinancialPositionDto["strategy"];
    }>
      form={form}
      onFinish={onFinish}
      title="Achtung!"
      text={labels.investmentSettings.strategyModalWarning}
    >
      <SFormItem
        name="investmentRisk"
        initialValue={risk}
        validateStatus={errorMessages.risk.range ? "error" : "success"}
        help={<div>{errorMessages.risk.range}</div>}
      >
        <RiskSlider
          isUnderage={isUnderageMode}
          marks={labels.investmentSettings.investmentRiskMarks}
          min={0}
          max={40}
          step={1}
          margin="16px 0 28px 0"
          labelMargin="0 0 40px 0"
          tipFormatter={(value?: number) => `${value ?? 0}% max. Drawdown`}
          onAfterChange={onRiskSliderAfterChange}
        />
      </SFormItem>
      <RadioGroup
        name="strategy"
        label="Ihr Anlageziel *"
        rules={labels.investmentSettings.rules.strategy}
        items={labels.investmentSettings.strategies}
        initialValue={strategy}
        direction="vertical"
        onChange={onStrategyChange}
        validateStatus={errorMessages.strategy.range ? "error" : "success"}
        help={<div>{errorMessages.strategy.range}</div>}
      />
      <SActionButtonsWrapper style={{ margin: 0, marginTop: 32 }}>
        <SButton
          className="reset"
          onClick={onCancel}
          data-testid="close-risk-modal"
        >
          Abbrechen
        </SButton>

        <SButton
          htmlType="submit"
          type="primary"
          disabled={isSubmit || !!errorList.length}
          loading={isSubmit}
        >
          Ändern
        </SButton>
      </SActionButtonsWrapper>
    </FormInModal>
  );
};
