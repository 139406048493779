import type { FormInstance } from "antd";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import validateInvestmentSettingsOnClientSide from "@src/utils/validation/validateInvestmentSettingsOnClientSide";
import {
  openIndependentConfirmModal,
  openIndependentInfoModal
} from "@theme/modal/openThemedModal";
import type { NextRouter } from "next/router";
import { type KnowledgeDto } from "smavesto.core";
import type { AppTheme } from "smavesto.core/lib/static/themes/AppTheme";
import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

export const validateInvestmentSettings =
  (
    form: FormInstance,
    theme: AppTheme,
    onboardingLabels: any,
    source: "onboarding" | "dashboard",
    knowledge?: KnowledgeDto,
    router?: NextRouter,
    userUri?: string
  ) =>
  async (): Promise<boolean> => {
    const onlySustainableCompanies = form.getFieldValue(
      "onlySustainableCompanies"
    );
    const noForeignCurrency = !form.getFieldValue("foreignCurrency");
    const noEmergingMarkets = !form.getFieldValue("emergingMarkets");
    const investInCrypto = form.getFieldValue("investInCrypto");
    const investInGold = form.getFieldValue("investInGold");
    const risk = form.getFieldValue("investmentRisk") as number;

    const clientSideValidation = validateInvestmentSettingsOnClientSide(
      {
        investInCrypto,
        investInGold,
        noForeignCurrency,
        noEmergingMarkets,
        onlySustainableCompanies
      },
      knowledge,
      risk
    );

    if (clientSideValidation === "error.cryptoWithoutKnowledge") {
      const { destroy } = openIndependentConfirmModal(
        {
          ...onboardingLabels.investmentRisk.modalOptions.crypto,
          onOk: () =>
            router?.push(
              `/dashboard/profile?user=loggedInUser&section=knowledge&onSave=/dashboard/depot%3Fopen%3Dinvestment-settings%26forUser=${userUri}`
            ),
          onCancel: () => destroy()
        },
        theme
      );

      form.setFieldsValue({ investInCrypto: false });
      return true;
    }
    if (clientSideValidation === "error.riskWithCrypto") {
      const { destroy } = openIndependentInfoModal(
        {
          ...onboardingLabels.investmentRisk.modalOptions.cryptoRisk,
          onCancel: () => destroy()
        },
        theme
      );

      form.setFieldsValue({ investInCrypto: false });
      return true;
    }
    if (clientSideValidation === "error.sustainableWithGold") {
      openIndependentInfoModal(
        onboardingLabels.investmentRisk.modalOptions.investmentSettings,
        theme
      );
      form.setFieldsValue({
        investInGold: false,
        investInCrypto: false
      });
      return true;
    }

    if (clientSideValidation === "error.sustainableWithCrypto") {
      openIndependentInfoModal(
        onboardingLabels.investmentRisk.modalOptions.investmentSettings,
        theme
      );
      form.setFieldsValue({
        investInCrypto: false,
        investInGold: false
      });

      return true;
    }

    const queryParams = `onlySustainableCompanies=${onlySustainableCompanies}&noForeignCurrency=${noForeignCurrency}&noEmergingMarkets=${noEmergingMarkets}&investInCrypto=${investInCrypto}&investInGold=${investInGold}`;

    const validationResult = await smavestoCore.services.validation.validate(
      "validate-investment-settings",
      queryParams
    );

    if (isApiErrorResponse(validationResult)) {
      openIndependentInfoModal(
        onboardingLabels.investmentRisk.modalOptions.investmentSettings,
        theme
      );
      form.setFieldsValue({
        emergingMarkets: false,
        foreignCurrency: false,
        investInCrypto: false,
        investInGold: false
      });
      return true;
    }

    return false;
  };
