import type { SetterOrUpdater } from "recoil";

import type FinancialPositionDto from "smavesto.core/lib/types/dto/onboarding/FinancialPositionDto";

import type { StrategyRiskErrors } from "@states/strategy-risk.state";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";

export const updateStrategyRiskErrorState = <
  F extends keyof Omit<StrategyRiskErrors, "apiError">,
  K extends keyof StrategyRiskErrors[F]
>(
  isValid: boolean,
  setErrorMessages: SetterOrUpdater<StrategyRiskErrors>,
  field: F,
  key: K,
  message: string
) => {
  setErrorMessages(prev => {
    const next = isValid
      ? prev[field][key]
        ? { ...prev, [field]: { ...prev[field], [key]: "" } }
        : prev
      : !prev[field][key]
      ? { ...prev, [field]: { ...prev[field], [key]: message } }
      : prev;

    return next;
  });
};

export const updateRiskAndStrategyErrorsState = (
  isValid: boolean,
  setErrorMessages: SetterOrUpdater<StrategyRiskErrors>,
  riskMessage: string,
  strategyMessage: string
) => {
  updateStrategyRiskErrorState(
    isValid,
    setErrorMessages,
    "risk",
    "range",
    riskMessage
  );

  updateStrategyRiskErrorState(
    isValid,
    setErrorMessages,
    "strategy",
    "range",
    strategyMessage
  );
};

export const updateAndValidateStrategy = async (
  financialPosition: FinancialPositionDto | undefined,
  strategy: FinancialPositionDto["strategy"],
  userUri: string
) => {
  if (!financialPosition) return null;

  const body = { ...financialPosition, strategy };

  await smavestoCore.services.user.setFinancialSituation(userUri, body);

  const validationResult = await smavestoCore.services.validation.validate(
    "validate-anlageziel",
    `anlageziel=${strategy}`,
    userUri
  );

  if (isApiErrorResponse(validationResult)) return validationResult;

  return body;
};
