import type { InvestmentSettingsDto, KnowledgeDto } from "smavesto.core";

export type ClientSideInvestmentSettingsValidationResult =
  | "success"
  | "error.sustainableWithGold"
  | "error.sustainableWithCrypto"
  | "error.riskWithCrypto"
  | "error.cryptoWithoutKnowledge";

/**
 * Validates investment settings on the client side based on provided knowledge and risk tolerance.
 *
 * @param investmentSettings - The user's investment settings.
 * @param knowledge - Optional knowledge object to verify crypto investment experience.
 * @param risk - Optional risk tolerance level.
 * @returns The validation result, indicating success or specific validation errors.
 */
const validateInvestmentSettingsOnClientSide = (
  investmentSettings: InvestmentSettingsDto,
  knowledge?: KnowledgeDto,
  risk?: number
): ClientSideInvestmentSettingsValidationResult => {
  const { investInCrypto, investInGold, onlySustainableCompanies } =
    investmentSettings;

  // Validate crypto knowledge if provided
  if (investInCrypto && knowledge && !knowledge.experiences.crypto) {
    return "error.cryptoWithoutKnowledge";
  }

  // Validate risk tolerance if provided
  if (investInCrypto && risk !== undefined && risk < 35) {
    return "error.riskWithCrypto";
  }

  // Ensure sustainability restriction if investing in gold
  if (investInGold && onlySustainableCompanies) {
    return "error.sustainableWithGold";
  }

  // Ensure sustainability restriction if investing in gold
  if (investInGold && onlySustainableCompanies) {
    return "error.sustainableWithGold";
  }

  // Ensure sustainability restriction if investing in gold
  if (investInCrypto && onlySustainableCompanies) {
    return "error.sustainableWithCrypto";
  }

  // If no validation errors, return success
  return "success";
};

export default validateInvestmentSettingsOnClientSide;
